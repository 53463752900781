import * as React from "react";
import * as styles from "./about-us.module.css";

import { FunctionComponent, useCallback, useState } from "react";

import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const AboutUs: FunctionComponent = () => {
  return (
    <Layout>
      <div className={styles.aboutUs}>
        <div className={styles.aboutUsIntro}>
          <div className={styles.aboutUs1}>
            <div className={styles.aboutUsText}>
              <div className={styles.title}>
                <b className={styles.display}>About Us</b>
              </div>
              <div className={styles.heroText}>
                <div className={styles.textBox}>
                  <div className={styles.p}>
                    <div className={styles.caption}>
                      Simply Express is a leading global supplier of medical
                      equipment and devices. We provide healthcare solutions for
                      medical spas and clinics, dermatology clinics, private
                      practices, and medical supply wholesalers.
                    </div>
                  </div>
                  <div className={styles.p}>
                    <div className={styles.caption}>
                      Our network of international partners and suppliers allows
                      us to offer our clients medical products at the lowest
                      prices. We know that being able to provide patients with
                      quality care and services is the key to our clients’
                      success, so we work hard to provide a large selection of
                      devices and the best brand names. With quick delivery and
                      reliable service, you can depend on Simply Express
                      products for your business to grow.
                    </div>
                  </div>
                  <div className={styles.p}>
                    <div className={styles.caption}>
                      <p className={styles.yourSafetyIs}>
                        Your safety is our top priority. We also use SSL
                        encryption to secure all of your personal information
                        that’s transferred through our website when transactions
                        are completed.
                      </p>
                      <p className={styles.yourSafetyIs}>&nbsp;</p>
                      <p className={styles.viewOurProduct}>
                        View our product catalogue for competitive prices and
                        trusted brands.
                      </p>
                      <br></br>
                    </div>
                  </div>
                </div>
                <div className={styles.imageAbout}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="../image6@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className={styles.features}>
              <div className={styles.featuresRow}>
                <div className={styles.featureSlot}>
                  <div className={styles.aboutUsText}>
                    <div className={styles.iconBox}>
                      <img className={styles.eyeIcon} alt="" src="../eye.svg" />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label}>Our Vision</div>
                    </div>
                    <div className={styles.featuretext}>
                      <div className={styles.label1}>
                        At Simply Express, we want to ensure that medical
                        professionals and suppliers have access to the products
                        and equipment they need, at an affordable price. With
                        our global network of partners, we provide our customers
                        with high-quality medical supplies that help their
                        patients live a healthier, happier life. We aim to be a
                        recognized, dependable source for professional service
                        and the highest-quality medical devices.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.featureSlot}>
                  <div className={styles.aboutUsText}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.eyeIcon}
                        alt=""
                        src="../flag3.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label}>Our Mission</div>
                    </div>
                    <div className={styles.featuretext}>
                      <div className={styles.label1}>
                        Our mission is to provide an extensive product range of
                        the highest-quality brands of medicine and medical
                        supplies at an affordable price. With consistent,
                        reliable service, we strive to leave every one of our
                        customers satisfied with their experience with Simply
                        Express. By leveraging our vast network of global
                        suppliers, we offer our customers the lowest prices for
                        the healthcare devices their patients need.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;

export const Head = () => (
  <SEO title="Simply Express | About us" description="About us." />
);
