// extracted by mini-css-extract-plugin
export var aboutUs = "about-us-module--aboutUs--lx67N";
export var aboutUs1 = "about-us-module--aboutUs1--WQkIj";
export var aboutUsIntro = "about-us-module--aboutUsIntro--6ctwt";
export var aboutUsText = "about-us-module--aboutUsText---mOcT";
export var bannerSuper = "about-us-module--bannerSuper--OR+xD";
export var button = "about-us-module--button--GGPxF";
export var caption = "about-us-module--caption--ORkjZ";
export var ccs = "about-us-module--ccs--ASlFG";
export var cdihealthlogoIcon = "about-us-module--cdihealthlogoIcon--oVyXk";
export var cdihealthlogoIcon1 = "about-us-module--cdihealthlogoIcon1--vMCTM";
export var column = "about-us-module--column--YfC7S";
export var display = "about-us-module--display--Sth9l";
export var email = "about-us-module--email--74es3";
export var eyeIcon = "about-us-module--eyeIcon--HIoh-";
export var featureLabel = "about-us-module--featureLabel--LXhH2";
export var featureSlot = "about-us-module--featureSlot--HdyxS";
export var features = "about-us-module--features--kCmvt";
export var featuresRow = "about-us-module--featuresRow--b9ydL";
export var featuretext = "about-us-module--featuretext--HARac";
export var footer = "about-us-module--footer--ORjjJ";
export var footerContent = "about-us-module--footerContent--fl0w6";
export var footerL = "about-us-module--footerL--ZTeus";
export var footerLinks = "about-us-module--footerLinks--7Rth4";
export var footerR = "about-us-module--footerR--eujKl";
export var fullNavBar = "about-us-module--fullNavBar--kxETT";
export var globalCareSupply = "about-us-module--globalCareSupply--3Tmyr";
export var heroText = "about-us-module--heroText--KT+Px";
export var iconBox = "about-us-module--iconBox--xjNIr";
export var iconButton = "about-us-module--iconButton--FiDIA";
export var iconFilled = "about-us-module--iconFilled--IpMkO";
export var imageAbout = "about-us-module--imageAbout--X-q0z";
export var imageIcon = "about-us-module--imageIcon--mHCzy";
export var infocdihealthnet = "about-us-module--infocdihealthnet--B03OU";
export var label = "about-us-module--label--ydwMd";
export var label1 = "about-us-module--label1--putHq";
export var logo = "about-us-module--logo--k1VNU";
export var navbarContent = "about-us-module--navbarContent--awCoM";
export var navbarContentMobile = "about-us-module--navbarContentMobile--c5g+B";
export var navbarMobileR = "about-us-module--navbarMobileR--p12eS";
export var navigation = "about-us-module--navigation--ExPWf";
export var navigation1 = "about-us-module--navigation1--hh4kw";
export var p = "about-us-module--p--m3E3g";
export var privacyPolicy = "about-us-module--privacyPolicy--AZLSC";
export var textBox = "about-us-module--textBox--Klj1H";
export var title = "about-us-module--title--Z-Sx3";
export var viewOurProduct = "about-us-module--viewOurProduct--8+0bJ";
export var visaIcon = "about-us-module--visaIcon--NVmZy";
export var yourSafetyIs = "about-us-module--yourSafetyIs--XrwO7";